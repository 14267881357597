import Vue from 'vue';
import axios from 'axios'
import store from '../store'
import Router from '../router/index'
import { geolocation } from './index'
import { merchantId } from './api'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

let config = {
  baseURL: process.env.NODE_ENV === 'development'? '/api': '',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  async function(config) {
    // 在发送请求之前做些什么
    config.headers.r = new Date().getTime();
    config.headers['accessToken'] = store.state.accessToken
    config.headers['source'] = 'H5'
    let channel = Router.history.current.query.channel || localStorage['channel']
    if (channel) {
      localStorage['channel'] = channel
      config.headers['channel'] = channel
    }
    config.headers['gps'] = await geolocation()
    config.headers['merchantId'] = merchantId
    config.headers['inputChannel'] = 'ANTCREDITO'
    config.headers['versionId'] = '20240130'
    return config;
  },
  function(error) {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 添加响应拦截器
_axios.interceptors.response.use(
  function(response) {
    // 处理响应数据
    // 登录过期
    try {
      if (response.data.status.code === '997') {
        store.commit('SET_ACCESS_TOKEN');
        store.commit('SET_USER', {});
        Router.push('/user/login');
      }
    } catch(e) {
      console.log(e);
    }
    return Promise.resolve(response.data);
  },
  function(error) {
    let data = error.data? error.data: (error.request.response && JSON.parse(error.request.response));
    // 处理响应错误
    return Promise.reject(data);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;