import Vue from 'vue';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const myPlugin = {
  install(Vue) {
    Vue.NProgress = NProgress;
    window.NProgress = NProgress;
    Object.defineProperties(Vue.prototype, {
      NProgress: {
        get() {
          return NProgress;
        }
      },
      $NProgress: {
        get() {
          return NProgress;
        }
      },
    });
  }
}
Vue.use(myPlugin)

export default myPlugin;