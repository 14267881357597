export async function geolocation() {
  let gps = window.localStorage.getItem('gps') || '0,0'

  if (gps && gps != '0,0') {
    getGps()
  } else {
    await getGps()
    gps = window.localStorage.getItem('gps') || '0,0'
  }

  return gps
}

function getGps() {
  return Promise.race([
    new Promise((resolve) => {
      setTimeout(() => resolve(), 3000)
    }),
    new Promise((resolve) => {
      if (navigator.geolocation){
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let longitude = position.coords.longitude.toFixed(5); // 获取经度
            let latitude = position.coords.latitude.toFixed(5); // 获取纬度
            window.localStorage.setItem('gps', `${longitude},${latitude}`)
            resolve()
          }, function() {
            resolve()
          }, {
            maximumAge: 5 * 60 * 1000,
          }
        );
      }
    }),
  ])
}
