export default function gtmPageView (page) {
  let gtm = page.$route.query['gtm'] || localStorage['gtm']
  //  Google Tag Manager pageView
  if (gtm) {
    localStorage['gtm'] = gtm
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'PageView'
    })
  }
}
