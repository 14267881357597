// export const merchantId = '3878b2ecee9942702f822f9bfd0ac1c5';

export const merchantId = '428dfd43575701522eaac643a4586eda';
export const oneImage = '/smart-loan/image/v2/oneImage'; // 上传图片
export const oneVideo = '/smart-loan/apply/v2/oneImage'; // 上传视频
export const brand = '/smart-loan/system/brand'; // 获取公司信息
export const getUserInfo = '/smart-loan/user/getUserInfo'; // 获取用户信息
export const processList= '/smart-loan/market/contract/repayAmountList';
export const queryStatus = '/smart-loan/apply/queryStatus'; // 获取上笔订单信息
export const getUserInfoDetail = '/smart-loan/user/getUserInfoDetail'; // 获取用户信息详情
export const userBankCard = '/smart-loan/user/bank/card'; // 获取用户银行卡信息
export const session = '/smart-loan/user/login'; // 登录
export const validationCode = '/smart-loan/app/getValidateCode'; // 获取验证码
export const modifyPin = '/smart-loan/user/modify/pin'; // 修改PIN码
export const registeredUser = '/smart-loan/user/registeredUser'; // 注册
export const contractList = '/smart-loan/market/contract/list'; // 查询合同列表
export const contractDetail = '/smart-loan/market/contract/detailInfo'; // 查看合同详情
export const currentContractList = '/smart-loan/market/contract/current'; // 查看合同详情
export const applyStep = '/smart-loan/apply/step'; // 申请
export const sign = '/smart-loan/market/apply/sign'; // 申请
export const product = '/smart-loan/product/v1/getProduct'; // 获取产品
export const productList = '/smart-loan/market/product/getProductList'; // 获取产品列表
export const scheduleCalc = '/smart-loan/contract/scheduleCalc'; // 获取试算
export const getRecommendUrl = '/smart-loan/recommend/getRecommendUrl'; // 获取邀请链接
export const myRecommend = '/smart-loan/recommend/myRecommend'; // 我的推广
export const myCoupon = '/smart-loan/coupon/myCoupon'; // 我的优惠卷
export const useMyCoupon = '/smart-loan/coupon/useMyCoupon'; // 使用优惠卷
export const behavior = '/smart-loan/app/behavior'; // 用户行为
export const continuedLoan = '/smart-loan/apply/continuedLoan'; // 是否续贷
export const loanAgreement = '/smart-loan/contract/loan/agreement'; // 预览合同
export const logout = '/smart-loan/user/logout'; // 退出登录
export const repayCode = '/smart-loan/contract/repayCode'; // 还款码
export const supplementSubmit = '/smart-loan/apply/supplementSubmit'; // 补件
export const barCode = '/smart-loan/contract/barCode'; // 还款条形码
export const repayList = '/smart-loan/market/contract/repayList'; // 还款单
export const updInfo = '/smart-loan/customer/updateBankInfo'; // 更新客户信息
export const bankAccount = '/smart-loan/pay/verify/bankAccount'; // 校验银行账户
export const payBarCode = '/smart-loan/pay/barCode'; // 获取还款条形码
export const payClabe = '/smart-loan/pay/clabe'; // 获取还款码
export const getRecommendProduct = '/smart-loan/market/product/getRecommendProduct'//首页获取产品

// 字典
export const dictionary = '/smart-loan/dictionary/'; // 字典
export const EDUCATION = '/smart-loan/dictionary/EDUCATION'; // 学历
export const MARITAL_STATUS = '/smart-loan/dictionary/MARITAL_STATUS'; // 婚姻状况
export const DISTRICT = '/smart-loan/dictionary/DISTRICT'; // 地址
export const INDUSTRY = '/smart-loan/dictionary/INDUSTRY'; // 行业
export const PROFESSION = '/smart-loan/dictionary/PROFESSION'; // 职务
export const MONTHLY_INCOME = '/smart-loan/dictionary/MONTHLY_INCOME'; // 平均月收入
export const RELATIONSHIP = '/smart-loan/dictionary/RELATIONSHIP'; // 亲属联系人
export const OTHER_RELATIONSHIP = '/smart-loan/dictionary/OTHER_RELATIONSHIP'; // 其他联系人
export const BANK = '/smart-loan/dictionary/BANK'; // 获取银行
export const CARD_TYPE = '/smart-loan/dictionary/CARD_NO_TYPE';
export const PRIMAARYID = '/smart-loan/dictionary/PRIMAARYID'; // 证件类型
export const PAY_ORG = '/smart-loan/dictionary/PAY_ORG'; // 线下取款机构
export const CONVENIENT_TIME = '/smart-loan/dictionary/CONVENIENT_TIME'; // 联系时间段
export const LOAN_PURPOSE = '/smart-loan/dictionary/LOAN_PURPOSE'; // 贷款目的
export const RELIGION = '/smart-loan/dictionary/RELIGION'; // 宗教
export const PAY_CYCLE = '/smart-loan/dictionary/PAY_CYCLE'; // 发薪类型
export const PAY_CHL = '/smart-loan/dictionary/PAY_CHL'; // 通道
export const INCUMBENCY = '/smart-loan/dictionary/INCUMBENCY'; // 在职时长
