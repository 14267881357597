<template>
  <div class="home">
    <div class="apply">
      <mt-header>
        <div class="msg" slot="right" @click="viewMsg">
        </div>
      </mt-header>
      <div class="welcome-tip">
        {{ $t('hello_welcome') }}
      </div>
      <div class="step" v-if="user.applyId != null">
        {{ stepTip }}
      </div>
      <div class="process-items" v-if="this.processList.length > 0">
        <div class="process-item" v-for="(item, index) in this.processList" v-bind:key="index">
          <div :class="'process-count'+(index+1)">{{item.count}}</div>
          <div class="process-status">{{item.status}}</div>
        </div>
      </div>
      <div class="amount-item" v-else-if="this.processList.length <= 0">
        <div class="amount">
          {{ amount }}
        </div>
        <div class="max-view-amt-tip">
          {{ $t('max_available_amount') }}
        </div>
      </div>
      <div class="apply-item">
        <div class="apply-logo2">
        </div>
        <div class="apply-btn">
          <mt-button size="large" @click="submit">{{
              $t("apply")
            }}
          </mt-button>
        </div>
        <div class="apply-logo1">
        </div>
        <div class="home_hint">
          {{ homeHint }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <div class="item-tip">
          <div class="item-tip-text">
            {{ $t('product_title') }}
          </div>

        </div>
        <!-- 产品信息展示 -->
        <div class="product">
          <mt-swipe :auto="4000">
            <mt-swipe-item class="product-item" v-for="(item,index) in productList" :key="index">
              <div class="product-item-left">
                <div class="product-name">
                  {{ item.productName }}
                </div>
                <div class="product-desc">
                  {{ item.productDesc }}
                </div>
                <div class="product-app-btn">
                  <mt-button size="large" @click="submit">{{
                      $t("apply")
                    }}
                  </mt-button>
                </div>
              </div>
              <div class="product-item-right">
                <div class="product-desc">
                  <img :src="item.imageLink" :alt="item.productName">
                </div>
              </div>

            </mt-swipe-item>
          </mt-swipe>
        </div>
      </div>
      <div class="item">
        <div class="item-tip">
          <div class="item-tip-text">
            {{ $t('step_title') }}
          </div>
        </div>
        <div class="step">
          <div class="step-item card-01">
            <div class="step-item-tip">
              <div class="step-item-tip01">
                {{ $t('step_list_1_title') }}
              </div>
              <div class="step-item-tip02">
                {{ $t('step_list_1_value') }}
              </div>
            </div>

          </div>
          <div class="step-item card-02">
            <div class="step-item-tip">
              <div class="step-item-tip01">
                {{ $t('step_list_2_title') }}
              </div>
              <div class="step-item-tip02">
                {{ $t('step_list_2_value') }}
              </div>
            </div>

          </div>
          <div class="step-item card-03">
            <div class="step-item-tip">
              <div class="step-item-tip01">
                {{ $t('step_list_3_title') }}
              </div>
              <div class="step-item-tip02">
                {{ $t('step_list_3_value') }}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-tip">
          <div class="item-tip-text">
            {{ $t('reason_title') }}
          </div>
        </div>
        <div class="reason">
          <div class="reason-item reason-left">
            <div class="reason-list">
              <div class="reason-item-01">
                {{ $t('reason_list_1_title') }}
              </div>
              <div class="reason-item-02">
                {{ $t('reason_list_1_value') }}
              </div>
            </div>

          </div>
          <div class="reason-item reason-right">
            <div class="reason-list">
              <div class="reason-item-01">
                {{ $t('reason_list_2_title') }}
              </div>
              <div class="reason-item-02">
                {{ $t('reason_list_2_value') }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <tab-bar />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {getUserInfo, applyStep, contractDetail, getRecommendProduct, processList} from '../utils/api'
import tabBar from '@/components/tabBar.vue'
import branch from '../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../utils/gtm.js'
export default {
  name: 'Home',
  components: {
    tabBar,
  },
  data() {
    return {
      title: this.$t('homeLoanTips1'),
      amount: '????',
      stepTip: this.$t('apply_progress'),
      homeHint: this.$t('home_hint'),
      productList:[],
      processList: []
    }
  },
  computed: {
    ...mapState(["brand", "user"]),
    privacyUrl() {
      if (this.brand.channelInfo) {
        return this.brand.channelInfo.privacyUrl
      }
      return ''
    }
  },
  watch: {
    
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P01_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P01_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_CHECKED_SERVICE_AGREEMENT"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    // 查看消息
    viewMsg(){
      console.log("查看消息")
    },
    async getProductList(){
      await this.$axios({
        method: 'post',
        url: getRecommendProduct,
      }).then((e) => {
        if (e.status.code == '000') {
          this.productList = e.body
        }
      }).catch(() => {})
    },
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getUser(),
      ])
      await this.getProcessList()
      // 初始信息设置：步骤设置
      await this.initInfoHandler()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async initInfoHandler(){
      // 获取当前用户的步骤
      const {currentStep,totalSteps} = this.$store.state.user
      // ${currStep}/${totStep}
      this.stepTip = this.stepTip.replace('${currStep}',currentStep?currentStep:'1').replace('${totStep}',totalSteps?totalSteps:'8')
      //主页提示处理
      this.homeHint = this.homeHint.replace('%s','6600')
      // 获取产品列表
      await this.getProductList()
    },
    submit() {
      switch (this.user.applyStatus) {
        case 'EMPTY':
        case 'APPLY':
        case 'REJECTED':
        case 'CANCEL':
        case 'SETTLE':
        case 'FREEZE':
        case 'EXPIRED':
          if (this.user.continuedLoan === 'Y') {
            this.onReApply() // 续贷申请
            break
          }
          this.onApplyPre() // 首贷申请
          break
        case 'WAIT':
        case 'LOAN':
        case 'NORMAL':
        case 'OVERDUE':
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
        case 'SUPPLEMENTARY':
        case 'SUPPLEMENT_IMAGE': // 补证件
        case 'SUPPLEMENT_BANK_CARD': // 补银行卡
        case 'SUPPLEMENT_IMAGE_CARD': // 补证件 & 银行卡
          this.$router.push({path: '/step/supplementary'}) // 补充资料
          break
        default:
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
      }
    },
    // 预申请
    async onApplyPre() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('01_FIRST_LOAN_APPLY', { content_name: '01_FIRST_LOAN_APPLY' })
        }
        // Google Analytics  FIRST_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'FIRST_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      
      await this.$axios({
        method: "post",
        url: applyStep,
        data: {
          currentStep: 1,
          totalSteps: 8,
          phone: this.user.phone,
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            if ("Y" == e.body.isWhiteListFlag) {
              this.$router.push('/step/relationshipauth')
            } else {
              this.$router.push('/step/jobauth')
            }
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()

      branch.logEvent('01_FIRST_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 续贷申请
    async onReApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('11_CONTINUED_LOAN_APPLY', {
            content_name: '11_CONTINUED_LOAN_APPLY'
          })
        }
        // Google Analytics  CONTINUED_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'CONTINUED_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: {
          currentStep: 1,
          totalSteps: 8,
          continuedLoan: 'Y',
          phone: this.user.phone,
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('11_CONTINUED_LOAN_APPLY', { metadata: this.user.phone })
    },
    onServiceAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.privacyUrl)
      }
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
          if(e.body.maxAmount){
            this.amount = e.body.maxAmount
          }
          switch (e.body.applyStatus) {
              case "EMPTY":
              case "APPLY":
              case "SIGN":
              case "APPROVE":
              case "LOAN":
              case "WAIT":
                this.title = this.$t('homeLoanTips1')
                break;
              case "REPAY":
                this.title = this.$t('homeLoanTips2')
                this.getDetails("REPAY");
                break;
              default:
                this.title = this.$t('homeLoanTips3')
                this.getDetails("OTHER");
                break;
          }
        }
      }).catch(() => {})
    },
    async getProcessList() {
      await this.$axios({
        method: "GET",
        url: processList,
      }).then((e) => {
        if (e.status.code === "000"  ) {
          this.processList = e.body
        }
      }).catch(() => {})
    },
    async getDetails(text) {
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: contractDetail,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            if ('REPAY' == text) {
              this.amount = e.body.repayAmount
            } else {
              this.amount = e.body.applyAmount
            }
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  position: relative;
  min-height: 100vh;
  background-image: url('../assets/home_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  /deep/.mint-header{
    background-color: transparent;
    border-bottom: transparent;
  }
  .apply{
    background-image: url('../assets/home_bg1.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    text-align: center;
    color: #ffffff;
    .msg{
      position: absolute;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 30px;
      background: url("../assets/msg.png") no-repeat ;
      background-size: 100% auto;
    }
    .welcome-tip{
      margin-top: 15px;
      font-size: 30px;
      font-weight: 600;
    }
    .step{
      margin-top: 20px;
      font-size: 15px;
      padding-left: 8vw;
      padding-right: 8vw;
      color: #f1e6ed;
    }
    .process-items{
      text-align: center;
      margin: 50px 20px 20px 20px;
      display: flex;
      justify-content:center;
      position: relative;
      .process-item{
        padding: 0 5px 0 5px;
        border-right: 1px solid #FFFFFF;
      }
      .process-item:last-child{
        border: none;
      }
      .process-count1{
        color: #2de30d;
        font-weight: bold;
      }
      .process-count2{
        color: #ec2010;
        font-weight: bold;
      }
      .process-count3{
        color: #f1c70b;
        font-weight: bold;
      }
      .process-status{
        margin-top: 10px;
        font-size: 12px;
      }
    }
    .amount-item{
      .amount{
        margin-top: 20px;
        font-size: 50px;
        font-weight: 700;
      }
      .max-view-amt-tip{
        margin-top: 5px;
        font-size: 14px;
        padding-left: 8vw;
        padding-right: 8vw;
        color: #d5c9fd;
      }
    }
    .apply-item{
      position: relative;
      margin-top: 290px;
      .apply-btn{
        padding-left: 8vw;
        padding-right: 8vw;
        .mint-button{
          height:50px;
          border-radius: 50px;
          font-weight: 600;
          background: linear-gradient(#F9F6FF, #E2D6FF);
          color: #855ef4;
        }
      }
      .apply-logo1{
        position: absolute;
        top: -40px;
        left: 30px;
        height: 50px;
        width: 50px;
        background-image: url('../assets/apply-logo1.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      .apply-logo2{
        position: absolute;
        top: -28px;
        right: 0;
        height: 60px;
        width: 70px;
        background-image: url('../assets/apply-logo2.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      .home_hint{
        margin-top: 10px;
        padding-left: 8vw;
        padding-right: 8vw;
        color: #d5c9fd;
      }
    }
  }
  .content{
    .item{
      margin-top: 25px;
      padding-left: 8vw;
      padding-right: 8vw;
      .item-tip{
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        .item-tip-text{
          font-size: 18px;
          display: inline-block;
          max-width: 68vw;
          vertical-align:middle;
        }
        &::before{
          content: '';
          display: inline-block;
          margin-right: 10px;
          vertical-align:middle;
          width: 20px;
          height: 20px;
          background-image: url('../assets/item-tip-before.png');
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
        &::after{
          content: '';
          display: inline-block;
          margin-left: 10px;
          vertical-align:middle;
          width: 20px;
          height: 20px;
          background-image: url('../assets/item-tip-after.png');
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }
      .product{
        height: 120px;

        .product-item{
          box-sizing: border-box;
          padding: 15px;
          background-color: #f0eaff;
          border-radius: 20px;
          .product-item-left{
            float: left;
            .product-name{
              font-weight: 700;
            }
            .product-desc{
              margin-top: 5px;
              color: #666666;
            }
            .product-app-btn{
              .mint-button{
                margin-top: 10px;
                height:30px;
                border-radius: 50px;
                background-color: transparent;
                border: 1px solid #855ef4;
                color: #855ef4;
              }
            }
          }
          .product-item-right{
            float: right;
            .product-desc{
              float: right;
              width: 80px;
              height: 80px;
              img{
                width: 100%;
              }
            }
          }
        }
      }
      .step{
        .step-item{
          position: relative;
          width: 100%;
          margin-top: 15px;
          .step-item-tip{
            padding: 30px 25px;
            .step-item-tip01{
              color: #855ef4;
              font-size: 20px;
              font-weight: 600;
            }
            .step-item-tip02{
              margin-top: 10px;
              color: #af95f8;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
        .card-01,.card-02,.card-03{
          height: 198px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .card-01{
          background-image: url('../assets/step_01.png');
        }
        .card-02{
          background-image: url('../assets/step_02.png');
        }
        .card-03{
          background-image: url('../assets/step_03.png');
        }
      }
      .reason{
        box-sizing: border-box;
        .reason-item{
          display: inline-block;
          box-sizing: border-box;
          width: calc(50% - 8px);
          height: 208px;
          background-repeat: no-repeat;
          background-size: 100% auto;
          .reason-list{
            float: left;
            .reason-item-01,.reason-item-02{
              text-align: center;
              padding: 0 5%;
            }
            .reason-item-01{
              margin-top: 20px;
              font-size: 14px;
              font-weight: 600;
            }
            .reason-item-02{
              margin-top: 10px;
              color: #686868;
              font-size: 11px;
            }
          }

        }
        .reason-left{
          background-image: url('../assets/reason-left.png');
          margin-right: 8px;
        }
        .reason-right{
          background-image: url('../assets/reason-right.png');
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
