<template>
  <mt-tabbar v-model="selected" fixed>
    <mt-tab-item id="/">
      <img v-if="selected == '/'" slot="icon" src="../assets/home-select.png">
      <img v-else slot="icon" src="../assets/home-grey.png">
      {{ $t('loan') }}
    </mt-tab-item>
    <mt-tab-item id="/repayment">
      <img v-if="selected == '/repayment'" slot="icon" src="../assets/bill-select.png">
      <img v-else slot="icon" src="../assets/bill-grey.png">
      {{ $t('bills') }}
    </mt-tab-item>
    <mt-tab-item id="/mine">
      <img v-if="selected == '/mine'" slot="icon" src="../assets/mine-select.png">
      <img v-else slot="icon" src="../assets/mine-grey.png">
      {{ $t('mine') }}
    </mt-tab-item>
  </mt-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  data () {
    return {
      selected: '/',
    }
  },
  watch: {
    selected: {
      handler(val) {
        if (this.$route.path != val) {
          this.$router.push(val)
        }
      },
    }
  },
  created() {
    this.selected = this.$route.path
  }
}
</script>

<style lang="scss" scoped>
/deep/ .mint-tab-item.is-selected {
  color: #855ef4;
}
</style>