<template>
  <Footer :class="['footer', { 'spacing': spacing }]">
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.ccphone">
      {{ $t('telephone') }}:
      <a :href="'tel:' + brand.serviceInfo.ccphone">{{ brand.serviceInfo.ccphone }}</a>
    </div>
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.email">
      {{ brand.serviceInfo.email }}
    </div>
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.fb">
      <a :href="brand.serviceInfo.fb" target="_blank">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconfacebook" />
        </svg>
        Facebook
      </a>
    </div>
  </Footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['brand']),
    spacing() {
      let path = this.$route.path
      if (path == '/' || path == '/repayment' || path == '/mine' || path == '/apply/done') {
        return true
      }
      return false
    }
  },
}
</script>

<style scoped lang="scss">
.footer {
  background: #212121;
  color: #fff;
  font-size: 14px;
  &.spacing {
    padding-bottom: 60px;
  }
  .item {
    padding: 10px 20px 0px 20px;
    .icon {
      vertical-align: middle;
      font-size: 26px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
  a {
    text-decoration: none;
    outline: none;
    color: white;
  }
}
</style>