<template>
  <div id="app">
    <layouts-index>
      <router-view/>
    </layouts-index>
  </div>
</template>

<script>
import { mapState } from "vuex";
import layoutsIndex from '@/layouts/index'
import Vue from 'vue'
import branch from '../node_modules/branch-sdk'
export default {
  components: {
    layoutsIndex
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    '$route.fullPath': {
      handler() {
        // 设置语言
        let lang = this.$route.query['lang'] || localStorage['lang']
        if (lang && ['mx', 'en', 'zh'].includes(lang)) {
          this.$i18n.locale = lang
          localStorage['lang'] = lang
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$axios({
      url: '/smart-loan/app/index',
      method: 'post',
      data: {
        userId: this.user.userId
      }
    })
      .then(() => {})
      .catch(() => {})
    // 自定义事件
    let pixel = this.$route.query['pixel'] || localStorage['pixel']
    if (pixel) {
      Vue.analytics.fbq.init(pixel, { em: 'jlusz@hotmail.com' })
      localStorage['pixel'] = pixel
    }
    branch.init('key_live_oaPpuem76a3oHKuDt5cFipfiswixE5B0')
    // ?utm_source=facebook&utm_medium=cpl&utm_campaign=cpl&utm_term=test&utm_content=entertainment&channel=facebook
  },
  mounted() {
    // <!-- Google Tag Manager -->
    let gtm = this.$route.query['gtm'] || localStorage['gtm']
    // 默认 GTM-W4BFQ2K
    let prGtm = 'GTM-W4BFQ2K'
    if (gtm) {
      localStorage['gtm'] = gtm
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      let f = document.getElementsByTagName('script')[0]
      let j = document.createElement('script')
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtm
      f.parentNode.insertBefore(j, f)
      if (gtm !== prGtm) {
        let j1 = document.createElement('script')
        j1.async = true
        j1.src = 'https://www.googletagmanager.com/gtm.js?id=' + prGtm
        f.parentNode.insertBefore(j1, f)
      }
    }
    //  Global site tag (gtag.js) - Google Analytics
    let ga = this.$route.query['ga'] || localStorage['ga']
    if (ga) {
      localStorage['ga'] = ga
      const awords = document.createElement('script')
      awords.type = 'text/javascript'
      awords.src = 'https://www.googletagmanager.com/gtag/js?id=' + ga
      document.body.appendChild(awords)
      window.dataLayer = window.dataLayer || []
      window.gtag = function() {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', ga)
      window.gtag('event', 'PageView')
    }
  }
}
</script>

<style lang="scss">
html, body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.mint-header {
  background-color: white;
  border-bottom: 1px solid #E5E5E5;
  color: #393939;
  font-size: 18px;
  height: 47px;
  .router-link-active {
    font-size: 16px;
  }
}
.mint-checkbox-input:checked + .mint-checkbox-core {
  background-color: #4B933E;
  border-color: #4B933E;
}
</style>
<style>
.mint-button--primary {
  color: #fff;
  background: linear-gradient(to bottom, #BB97FA, #855EF4);
}
</style>
